import axios from "axios";
import { api } from "../../../../helpers/topline-api";
import { URL_CONSTANTS } from "../../../../constants/urlConstants";

export async function getLongLivedUserToken(slug, userAccessToken) {
  try {
    const response = await api.post(`/api/get-long-lived-tokens/${slug}`, {
      userAccessToken,
    });

    const longLivedToken = response.data.message;
    return longLivedToken;
  } catch (error) {
    console.log("error:", error);
  }
}

export async function getOtherFacebookTokens(userAccessToken) {
  try {
    const response = await axios.get(
      `https://graph.facebook.com/v14.0/me/accounts?access_token=${userAccessToken}`
    );
    const responseData = response?.data?.data;
    return responseData;
  } catch (error) {
    console.log("error:", error);
    return;
  }
}

export async function updateWebsiteOnFacebook(slug: string) {
  try {
    await api.post(`/api/update-website-on-facebook/${slug}`);
  } catch (error) {
    console.log("error updating website on Facebook:", error);
  }
}

export async function getFbPageUrl(fbPageId, fbPageAccessToken) {
  try {
    const response = await fetch(
      `https://graph.facebook.com/v14.0/${fbPageId}?fields=link&access_token=${fbPageAccessToken}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = await response.json();
    return responseData.link;
  } catch (error) {
    console.log("error:", error);
  }
}

export async function checkGoogleAuth(slug: string, tokenObj: any) {
  try {
    const parsedExpiryDate = Date.parse(tokenObj?.expiryDate) || 0;
    const tokenExpired = parsedExpiryDate - Date.parse(new Date()) < 0;

    if (tokenObj && tokenObj.refreshToken) {
      if (!tokenExpired) {
        return { status: true, data: tokenObj };
      } else {
        const refreshedTokenObj = await getGmbAccountInfo(slug);
        return { status: true, data: refreshedTokenObj };
      }
    } else {
      return { status: false };
    }
  } catch (error) {
    console.error("checkGoogleAuth", error);
    return { status: false };
  }
}

export async function checkUpdateGmbVerificationStatus(slug) {
  try {
    // Use the refresh token to get the new access token
    const verificationStatus = await api.get(
      `/api/update-gmb-verification-status/${slug}`
    );
    return verificationStatus.data.message;
  } catch (error) {
    console.error("checkGoogleAuth", error);
    return "";
  }
}

export type GoogleToken = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
  id_token: string;
};

type SaveGoogleTokenResponse = {
  status: string;
  message: string;
};

export async function storeGoogleTokens(
  tokenObj: GoogleToken,
  slug: string
): Promise<SaveGoogleTokenResponse> {
  try {
    const response = await api.post<SaveGoogleTokenResponse>(
      `/api/store-google-tokens/${slug}`,
      tokenObj
    );
    return response.data;
  } catch (error) {
    console.log("Error storing google tokens:", error);
    throw error;
  }
}

export async function storeGoogleContactsTokens(
  tokenObj: GoogleToken,
  slug: string
): Promise<SaveGoogleTokenResponse> {
  try {
    const response = await api.post<SaveGoogleTokenResponse>(
      `/api/store-google-contacts-tokens/${slug}`,
      tokenObj
    );
    return response.data;
  } catch (error) {
    console.log("Error storing google contacts tokens", error);
    throw error;
  }
}

export async function storeGoogleIds(location, slug) {
  try {
    const response = await api.post(`/api/store-google-ids/${slug}`, location);
    return response?.data;
  } catch (error) {
    console.log("store google ids:", error);
    return;
  }
}

async function getGmbAccountInfo(slug: any) {
  try {
    const gmbAccountInfoRes = await api.get(
      `/api/get-gmb-account-info/${slug}`
    );
    const gmbAccountInfo = gmbAccountInfoRes.data.message;
    return gmbAccountInfo;
  } catch (error) {
    console.error("getTestimonials", error);
    return null;
  }
}

export async function getGmbTokens(code: string, landing_page_id: number) {
  try {
    const gmbRes = await api.post(
      `${URL_CONSTANTS.PROSITE_BASE}/api/gmb-code-exchange`,
      {
        code,
        landing_page_id,
      }
    );
    const responseData = gmbRes?.data;
    return responseData;
  } catch (error) {
    console.log("error:", error);
  }
}

export async function updateGmbInfoDatabase(slug: any) {
  try {
    const response = await api.post(
      `/api/update-landing-page-on-connection-to-google/${slug}`
    );
    return response;
  } catch (e) {
    console.log("error:", e);
    return;
  }
}

export async function disconnectFromFacebookDb(slug) {
  await api.get(
    `/api/update-landing-page-on-disconnection-from-facebook/${slug}`
  );
}

export async function disconnectFromGoogleDb(slug) {
  await api.get(
    `/api/update-landing-page-on-disconnection-from-google/${slug}`
  );
}

export async function disconnectFromGoogleContacts() {
  await api.get(
    `/api/update-landing-page-on-disconnection-from-google-contacts`
  );
}

export async function inviteGmbLocationGroup(locationGroupId: any) {
  return await api
    .post(`/api/invite-gmb-location-group`, {
      location_group_id: locationGroupId,
    })
    .then((response) => response.data)
    .catch((error) => {
      throw Error(error);
    });
}

export function truncateEmail(email: string) {
  if (email?.length > 30) {
    const [localPart, domain] = email.split("@");
    const domainParts = domain.split(".");
    const [domainStart, domainEnd] = [
      domainParts[0].charAt(0),
      domainParts[0].charAt(domainParts[0].length - 1),
    ];
    const extension = domainParts[domainParts.length - 1];
    return `${localPart}@${domainStart}...${domainEnd}.${extension}`;
  } else {
    return email;
  }
}
